ul,
li {
  list-style-type: none;
  padding: 0;
}

.modal {
  transition: opacity 0.25s ease;
}
body.modal-active {
  overflow-x: hidden;
  overflow-y: visible !important;
}
